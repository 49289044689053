import Rails from "@rails/ujs"
import { headerMenu } from 'src/header_menu'
import { modal } from 'src/modal'
import { showLoginDialog } from 'src/show_login_dialog'

// Zend側でrails-ujsを使うための記述(Zend側では packs/application.js を読み込んでいないため)
if(!window._rails_loaded) {
  Rails.start();
}

headerMenu();
modal();
showLoginDialog();
