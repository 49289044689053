export const headerMenu = () => {
  $('header#suvaco-header22 .search-btn').click(function(){
    $('#header-search-form').toggleClass('open');
  });
  $('header#suvaco-header22 .sp-menu').click(function() {
    $('header .menu').show();
    $('body').css('overflow-y', 'hidden');
  });
  $('header#suvaco-header22 .sp-close-btn').click(function() {
    $('header .menu').hide();
    $('body').css('overflow-y', '');
  });
  $('header#suvaco-header22 .menu > li').click(function() {
    $(this).children('.sub-menu').toggle();
    $(this).toggleClass('open');
  });
  $('#suvaco-header22 .login__step1 .registration').click(function(){
    window.location.href = '/publics/sign_up';
  });
  $('#suvaco-header22 .to-step2').click(function(){
    $('#suvaco-header22 .login__step2').show();
    $('#suvaco-header22 .login__step1').hide();
    $('#suvaco-header22 .prereissue').hide();
  });
  $('#suvaco-header22 .to-prereissue').click(function(){
    $('#suvaco-header22 .prereissue').show();
    $('#suvaco-header22 .login__step1').hide();
    $('#suvaco-header22 .login__step2').hide();
  });
  $('#suvaco-header22 .to-step1').click(function(){
    $('#suvaco-header22 .login__step1').show();
    $('#suvaco-header22 .login__step2').hide();
    $('#suvaco-header22 .prereissue').hide();
  });
  $('#header-search').submit(function(e){
    if ($('#header-search__text').val().trim().length == 0) return false;

    window.location = $(this).attr('action') + $('#header-search__text').val();
    e.preventDefault();
  });
}
