/**
 * モーダルウィンドウの表示
 */
export const modal = () => {
  $(function() {
    const $open = $('.js-modal-open');
    const $close = $('.js-modal-close');
    const $backdrop = $('.background-black');
    const $submit = $('.template-select');

    $backdrop.on('click touchend', (e) => {
      e.preventDefault();
      $('.js-modal').removeClass('visible');
      $('body').removeClass('modal-open');
    });

    $open.on('click touchend', (e) => {
      e.preventDefault();
      const modalData = $(e.currentTarget).data();
      const modalId = modalData['modalId'];
      const $modal = $(`.js-modal[data-modal-id=${modalId}]`);

      if (!openableModal(modalId, e.currentTarget)) return;

      clearModalData($modal, modalId);
      setModalData($modal, modalData);
      $modal.addClass('visible');
      $('body').addClass('modal-open');
    });

    $close.on('click touchend', (e) => {
      e.preventDefault();
      const modalId = $(e.currentTarget).closest('.js-modal').data('modal-id');
      const $modal = $(`.js-modal[data-modal-id=${modalId}]`);

      $modal.removeClass('visible');
      $('body').removeClass('modal-open');
    });

    $submit.on('click touchend', (e) => {
      e.preventDefault();
      const modalId = $(e.currentTarget).closest('.js-modal').data('modal-id');
      const $modal = $(`.js-modal[data-modal-id=${modalId}]`);

      $('#comment').val($('#message-template').val());
      $modal.removeClass('visible');
      $('body').removeClass('modal-open');
    });

    function setModalData($modal, data){
      const modalId = data['modalId'];
      switch(modalId) {
        case 'file-download-modal':
          $modal.find('.file_name').text(data['fileName']);
          $modal.find('.file_size').text(data['fileSize']);
          $modal.find('.file_url').attr("href", data['fileUrl']);
          break;
      };
    };

    function clearModalData($modal, modalId){
      switch(modalId) {
        case 'file-download-modal':
          $modal.find('.file_name').text('');
          $modal.find('.file_size').text('');
          $modal.find('.file_url').attr("href", 'javascript:void(0)');
          break;
      };
    };

    function openableModal(modalId, eventTarget) {
      switch(modalId) {
        case 'memo-modal':
          const hasRoomFavorited = $(eventTarget).closest('.mypage-photo-list__list__block').find('.mypage-photo-list__list__block__title__heart').css('display') == 'block';
          return hasRoomFavorited ? true : false;
        case 'sub-menu-modal--favorite':
        case 'sub-menu-modal--setting':
        case 'sub-menu-modal--customer':
          return window.innerWidth < 577 ? true : false;
      };
      return true;
    }
  });
};
