export const showLoginDialog = () => {
  $(function() {
    // TOPページ以外は処理しない
    if (location.pathname !== '/') return;
  
    // fromクエリパラメータがなければ何も処理しない
    const searchParams = new URLSearchParams(window.location.search);
    if (!searchParams.has('from')) return;
    
    // ダイアログ表示
    $('body').addClass('modal-open');
    document.getElementById('login-modal').classList.add('visible');
  
    // ログインダイアログに切り替え
    document.getElementsByClassName('login__step1')[0].style.display = 'none';
    document.getElementsByClassName('login__step2')[0].style.display = 'block';
  });
}
